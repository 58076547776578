import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import {
    mergeMap
} from "rxjs/operators";

import { environment } from '../../../../../../../environments/environment';
import { of } from 'rxjs';
import { HttpUtilsService } from '../../../apps/e-commerce/_core/utils/http-utils.service';
import { HttpClient } from '@angular/common/http';
import { Estado } from '../models/Estado';
import { Cidade } from '../models/Cidade';
import { ModalidadeCurso } from '../models/ModalidadeCurso';
import { NivelCurso } from '../models/NivelCurso';
import { Unidade } from '../models/Unidade';
import { Pais } from '../models/Pais';
import { FormaIngresso } from '../models/FormaIngresso';
import { Necessidade } from '../models/Necessidade';
import { TipoOrigem } from '../models/TipoOrigem';
import { Origem } from '../models/Origem';
import { StatusPessoa } from '../models/StatusPessoa';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    private apiUrl = environment.apiUrl + '/utils';

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

    listarPaises(): any {
        const url = `${this.apiUrl + "/buscarPaises"}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let paises: Pais[] = [];
                res.data.forEach(ps => {
                    let pais  = new Pais();
                    pais.id   = ps.id;
                    pais.nome = ps.nome;
                    pais.sigla  = ps.sigla;

                    paises.push(pais);
                });

                return of(paises);
            })
        );
    }

    listarEstados(idPais: number): any {
        const url = `${this.apiUrl + "/buscarEstados"}/${idPais}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let estados: Estado[] = [];
                res.data.forEach(est => {
                    let estado  = new Estado();
                    estado.id   = est.id;
                    estado.nome = est.nome;
                    estado.uf   = est.uf;

                    estados.push(estado);
                });

                return of(estados);
            })
        );
    }

    listarCidades(idEstado: number): any {
        const url = `${this.apiUrl + "/buscarCidades"}/${idEstado}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let cidades: Cidade[] = [];
                res.data.forEach(cid => {
                    let cidade  = new Cidade();
                    cidade.id   = cid.id;
                    cidade.nome = cid.nome;
                    cidade.idEstado = cid.idEstado;

                    let estado  = new Estado();
                    estado.id   = cid.estado.id;
                    estado.nome = cid.estado.nome;
                    estado.uf   = cid.estado.uf;

                    cidade.estado = estado;

                    let pais  = new Pais();
                    pais.id   = cid.estado.pais.id;
                    pais.nome = cid.estado.pais.nome;
                    pais.sigla  = cid.estado.pais.sigla;

                    cidade.estado.pais = pais;

                    cidades.push(cidade);
                });

                return of(cidades);
            })
        );
    }

    listarTodasCidades(): any {
        const url = `${this.apiUrl + "/buscarTodasCidades"}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let cidades: Cidade[] = [];
                res.data.forEach(cid => {
                    let cidade  = new Cidade();
                    cidade.id   = cid.id;
                    cidade.nome = cid.nome;
                    cidade.idEstado = cid.idEstado;

                    let estado  = new Estado();
                    estado.id   = cid.estado.id;
                    estado.nome = cid.estado.nome;
                    estado.uf   = cid.estado.uf;

                    cidade.estado = estado;

                    let pais  = new Pais();
                    pais.id   = cid.estado.pais.id;
                    pais.nome = cid.estado.pais.nome;
                    pais.sigla  = cid.estado.pais.sigla;

                    cidade.estado.pais = pais;

                    cidades.push(cidade);
                });

                return of(cidades);
            })
        );
    }

    listarModalidadesCurso(): any {
        const url = `${this.apiUrl + "/buscarModalidadesCurso"}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let modalidades: ModalidadeCurso[] = [];
                res.data.forEach(mod => {
                    let modalidade       = new ModalidadeCurso();
                    modalidade.id        = mod.id;
                    modalidade.descricao = mod.descricao;

                    modalidades.push(modalidade);
                });

                return of(modalidades);
            })
        );
    }


    listarNiveisCurso(): any {
        const url = `${this.apiUrl + "/buscarNiveisCurso"}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let niveis: NivelCurso[] = [];
                res.data.forEach(niv => {
                    let nivel       = new NivelCurso();
                    nivel.id        = niv.id;
                    nivel.descricao = niv.descricao;

                    niveis.push(nivel);
                });

                return of(niveis);
            })
        );
    }


    listarUnidades(): any {
        const url = `${this.apiUrl + "/buscarUnidades"}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let unidades: Unidade[] = [];
                res.data.forEach(uni => {
                    let unidade         = new Unidade();
                    unidade.id          = uni.id;
                    unidade.nome        = uni.nome;
                    unidade.rua         = uni.rua;
                    unidade.numero      = uni.numero;
                    unidade.complemento = uni.complemento;
                    unidade.bairro      = uni.bairro;
                    unidade.idCidade    = uni.idCidade;
                    unidade.telefone    = uni.telefone;
                    unidade.email       = uni.email;

                    unidades.push(unidade);
                });

                return of(unidades);
            })
        );
    }

    listarFormaIngresso(): any {
        const url = `${this.apiUrl + "/buscarFormasIngresso"}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let formasIngresso: FormaIngresso[] = [];
                res.data.forEach(fi => {
                    let formaIngresso  = new FormaIngresso();
                    formaIngresso.id   = fi.id;
                    formaIngresso.descricao = fi.descricao;
                    formaIngresso.indEnem  = fi.indEnem;

                    formasIngresso.push(formaIngresso);
                });

                return of(formasIngresso);
            })
        );
    }

    listarNecessidades(): any {
        const url = `${this.apiUrl + "/buscarNecessidades"}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let necessidades: Necessidade[] = [];
                res.data.forEach(ncs => {
                    let necessidade  = new Necessidade();
                    necessidade.id   = ncs.id;
                    necessidade.descricao = ncs.descricao;

                    necessidades.push(necessidade);
                });

                return of(necessidades);
            })
        );
    }

    listarTiposOrigens(): any {
        const url = `${this.apiUrl + "/buscarTiposOrigens"}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let tiposOrigens: TipoOrigem[] = [];
                res.data.forEach(tpo => {
                    let tipoOrigem  = new TipoOrigem();
                    tipoOrigem.id   = tpo.id;
                    tipoOrigem.descricao = tpo.descricao;

                    tiposOrigens.push(tipoOrigem);
                });

                return of(tiposOrigens);
            })
        );
    }

    listarOrigens(): any {
        const url = `${this.apiUrl + "/buscarOrigens"}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let origens: Origem[] = [];
                res.data.forEach(tpo => {
                    let origem  = new Origem();
                    origem.id   = tpo.id;
                    origem.descricao = tpo.descricao;

                    origens.push(origem);
                });

                return of(origens);
            })
        );
    }

    listarStatusPessoa(): any {
        const url = `${this.apiUrl + "/buscarStatusPessoa"}`;
        const request = this.http.get<any>(url);

        return request.pipe(
            mergeMap(res => {
                let statusPessoas: StatusPessoa[] = [];
                res.data.forEach(stp => {
                    let statusPessoa  = new StatusPessoa();
                    statusPessoa.id   = stp.id;
                    statusPessoa.descricao = stp.descricao;

                    statusPessoas.push(statusPessoa);
                });

                return of(statusPessoas);
            })
        );
    }


}
