import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import {
  map, mergeMap
} from "rxjs/operators";
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { HttpUtilsService } from '../../components/apps/e-commerce/_core/utils/http-utils.service';
import { Lead } from '../../components/cadastros/_core_cadastros/models/Lead';
import { Pessoa } from '../../components/cadastros/_core_cadastros/models/Pessoa';
import { Inscricao } from '../../components/cadastros/_core_cadastros/models/Inscricao';
import { Dashboard } from '../../components/cadastros/_core_cadastros/models/Dashboard';
import { HistoricoStatus } from '../../components/cadastros/_core_cadastros/models/HistoricoStatus';
@Injectable({
  providedIn: 'root'
})

export class InscricaoService {
  private apiUrl = environment.apiUrl + '/inscricao';

  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

  cadastrarLead(lead: Lead): any {
    const url = `${this.apiUrl + "/novoLead"}`;
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.post(url, lead, { headers: httpHeader });
  }

  atualizarLead(pessoa: Pessoa): any {
    const url = `${this.apiUrl + "/atualizarLead"}`;
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.post(url, pessoa, { headers: httpHeader });
  }

  cadastrarNovaInscricao(inscricao: Inscricao): any {
    const url = `${this.apiUrl + "/novaInscricao"}`;
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.post(url, inscricao, { headers: httpHeader });
  }

  atualizarInscricao(inscricao: Inscricao): any {
    const url = `${this.apiUrl + "/atualizarInscricao"}`;
    const httpHeader = this.httpUtils.getHTTPHeaders();
    return this.http.post(url, inscricao, { headers: httpHeader });
  }



  buscarDadosDashboard(dataInicial?: Date, dataFinal?: Date, origem ?: number): any {
    let auxDtIni = null;
    let auxDtFim = null;
    if (dataInicial != null && dataFinal != null) {

      let auxDia = dataInicial.getDate();
      let auxMes = dataInicial.getMonth() +1;
      let auxAno = dataInicial.getFullYear();

      auxDtIni = auxDia + '-' + auxMes + '-' + auxAno;

      auxDia = dataFinal.getDate();
      auxMes = dataFinal.getMonth() +1;
      auxAno = dataFinal.getFullYear();

      auxDtFim = auxDia + '-' + auxMes + '-' + auxAno;
    }

    if(origem == undefined)
      origem = 0;

    const url = `${this.apiUrl + "/buscarDadosDashboard"}/${auxDtIni}/${auxDtFim}/${origem}`;
    const request = this.http.get<any>(url);
    return request.pipe(
      mergeMap(res => {
        let dashboard = new Dashboard();
        let dad = res.data;

        let filtro = new HistoricoStatus();
        if (dad.filtro != undefined) {
          filtro.lead = dad.filtro.lead;
          filtro.agendados = dad.filtro.agendados;
          filtro.inscritos = dad.filtro.inscritos;
          filtro.leadRDStation = dad.filtro.leadRDStation;
          filtro.matriculados = dad.filtro.matriculados;
          filtro.aprovado = dad.filtro.aprovado;
          filtro.naoAprovado = dad.filtro.naoAprovado;
          filtro.naoCompareceu = dad.filtro.naoCompareceu;
        }
        else {
          filtro.lead = 0;
          filtro.agendados = 0;
          filtro.inscritos = 0;
          filtro.leadRDStation = 0;
          filtro.matriculados = 0;
          filtro.aprovado = 0;
          filtro.naoAprovado = 0;
          filtro.naoCompareceu = 0;
        }

        let hoje = new HistoricoStatus();
        hoje.lead = dad.hoje.lead;
        hoje.agendados = dad.hoje.agendados;
        hoje.inscritos = dad.hoje.inscritos;
        hoje.leadRDStation = dad.hoje.leadRDStation;
        hoje.matriculados = dad.hoje.matriculados;
        hoje.aprovado = dad.hoje.aprovado;
        hoje.naoAprovado = dad.hoje.naoAprovado;
        hoje.naoCompareceu = dad.hoje.naoCompareceu;

        let mesAtual = new HistoricoStatus();
        mesAtual.lead = dad.mesAtual.lead;
        mesAtual.agendados = dad.mesAtual.agendados;
        mesAtual.inscritos = dad.mesAtual.inscritos;
        mesAtual.leadRDStation = dad.mesAtual.leadRDStation;
        mesAtual.matriculados = dad.mesAtual.matriculados;
        mesAtual.aprovado = dad.mesAtual.aprovado;
        mesAtual.naoAprovado = dad.mesAtual.naoAprovado;
        mesAtual.naoCompareceu = dad.mesAtual.naoCompareceu;

        let mes1Anterior = new HistoricoStatus();
        mes1Anterior.lead = dad.mes1Anterior.lead;
        mes1Anterior.agendados = dad.mes1Anterior.agendados;
        mes1Anterior.inscritos = dad.mes1Anterior.inscritos;
        mes1Anterior.leadRDStation = dad.mes1Anterior.leadRDStation;
        mes1Anterior.matriculados = dad.mes1Anterior.matriculados;
        mes1Anterior.aprovado = dad.mes1Anterior.aprovado;
        mes1Anterior.naoAprovado = dad.mes1Anterior.naoAprovado;
        mes1Anterior.naoCompareceu = dad.mes1Anterior.naoCompareceu;

        let mes2Anterior = new HistoricoStatus();
        mes2Anterior.lead = dad.mes2Anterior.lead;
        mes2Anterior.agendados = dad.mes2Anterior.agendados;
        mes2Anterior.inscritos = dad.mes2Anterior.inscritos;
        mes2Anterior.leadRDStation = dad.mes2Anterior.leadRDStation;
        mes2Anterior.matriculados = dad.mes2Anterior.matriculados;
        mes2Anterior.aprovado = dad.mes2Anterior.aprovado;
        mes2Anterior.naoAprovado = dad.mes2Anterior.naoAprovado;
        mes2Anterior.naoCompareceu = dad.mes2Anterior.naoCompareceu;

        let mes3Anterior = new HistoricoStatus();
        mes3Anterior.lead = dad.mes3Anterior.lead;
        mes3Anterior.agendados = dad.mes3Anterior.agendados;
        mes3Anterior.inscritos = dad.mes3Anterior.inscritos;
        mes3Anterior.leadRDStation = dad.mes3Anterior.leadRDStation;
        mes3Anterior.matriculados = dad.mes3Anterior.matriculados;
        mes3Anterior.aprovado = dad.mes3Anterior.aprovado;
        mes3Anterior.naoAprovado = dad.mes3Anterior.naoAprovado;
        mes3Anterior.naoCompareceu = dad.mes3Anterior.naoCompareceu;

        let mes4Anterior = new HistoricoStatus();
        mes4Anterior.lead = dad.mes4Anterior.lead;
        mes4Anterior.agendados = dad.mes4Anterior.agendados;
        mes4Anterior.inscritos = dad.mes4Anterior.inscritos;
        mes4Anterior.leadRDStation = dad.mes4Anterior.leadRDStation;
        mes4Anterior.matriculados = dad.mes4Anterior.matriculados;
        mes4Anterior.aprovado = dad.mes4Anterior.aprovado;
        mes4Anterior.naoAprovado = dad.mes4Anterior.naoAprovado;
        mes4Anterior.naoCompareceu = dad.mes4Anterior.naoCompareceu;

        let mes5Anterior = new HistoricoStatus();
        mes5Anterior.lead = dad.mes5Anterior.lead;
        mes5Anterior.agendados = dad.mes5Anterior.agendados;
        mes5Anterior.inscritos = dad.mes5Anterior.inscritos;
        mes5Anterior.leadRDStation = dad.mes5Anterior.leadRDStation;
        mes5Anterior.matriculados = dad.mes5Anterior.matriculados;
        mes5Anterior.aprovado = dad.mes5Anterior.aprovado;
        mes5Anterior.naoAprovado = dad.mes5Anterior.naoAprovado;
        mes5Anterior.naoCompareceu = dad.mes5Anterior.naoCompareceu;

        let mes6Anterior = new HistoricoStatus();
        mes6Anterior.lead = dad.mes6Anterior.lead;
        mes6Anterior.agendados = dad.mes6Anterior.agendados;
        mes6Anterior.inscritos = dad.mes6Anterior.inscritos;
        mes6Anterior.leadRDStation = dad.mes6Anterior.leadRDStation;
        mes6Anterior.matriculados = dad.mes6Anterior.matriculados;
        mes6Anterior.aprovado = dad.mes6Anterior.aprovado;
        mes6Anterior.naoAprovado = dad.mes6Anterior.naoAprovado;
        mes6Anterior.naoCompareceu = dad.mes6Anterior.naoCompareceu;

        let mes7Anterior = new HistoricoStatus();
        mes7Anterior.lead = dad.mes7Anterior.lead;
        mes7Anterior.agendados = dad.mes7Anterior.agendados;
        mes7Anterior.inscritos = dad.mes7Anterior.inscritos;
        mes7Anterior.leadRDStation = dad.mes7Anterior.leadRDStation;
        mes7Anterior.matriculados = dad.mes7Anterior.matriculados;
        mes7Anterior.aprovado = dad.mes7Anterior.aprovado;
        mes7Anterior.naoAprovado = dad.mes7Anterior.naoAprovado;
        mes7Anterior.naoCompareceu = dad.mes7Anterior.naoCompareceu;

        let mes8Anterior = new HistoricoStatus();
        mes8Anterior.lead = dad.mes8Anterior.lead;
        mes8Anterior.agendados = dad.mes8Anterior.agendados;
        mes8Anterior.inscritos = dad.mes8Anterior.inscritos;
        mes8Anterior.leadRDStation = dad.mes8Anterior.leadRDStation;
        mes8Anterior.matriculados = dad.mes8Anterior.matriculados;
        mes8Anterior.aprovado = dad.mes8Anterior.aprovado;
        mes8Anterior.naoAprovado = dad.mes8Anterior.naoAprovado;
        mes8Anterior.naoCompareceu = dad.mes8Anterior.naoCompareceu;

        let mes9Anterior = new HistoricoStatus();
        mes9Anterior.lead = dad.mes9Anterior.lead;
        mes9Anterior.agendados = dad.mes9Anterior.agendados;
        mes9Anterior.inscritos = dad.mes9Anterior.inscritos;
        mes9Anterior.leadRDStation = dad.mes9Anterior.leadRDStation;
        mes9Anterior.matriculados = dad.mes9Anterior.matriculados;
        mes9Anterior.aprovado = dad.mes9Anterior.aprovado;
        mes9Anterior.naoAprovado = dad.mes9Anterior.naoAprovado;
        mes9Anterior.naoCompareceu = dad.mes9Anterior.naoCompareceu;

        let mes10Anterior = new HistoricoStatus();
        mes10Anterior.lead = dad.mes10Anterior.lead;
        mes10Anterior.agendados = dad.mes10Anterior.agendados;
        mes10Anterior.inscritos = dad.mes10Anterior.inscritos;
        mes10Anterior.leadRDStation = dad.mes10Anterior.leadRDStation;
        mes10Anterior.matriculados = dad.mes10Anterior.matriculados;
        mes10Anterior.aprovado = dad.mes10Anterior.aprovado;
        mes10Anterior.naoAprovado = dad.mes10Anterior.naoAprovado;
        mes10Anterior.naoCompareceu = dad.mes10Anterior.naoCompareceu;

        let mes11Anterior = new HistoricoStatus();
        mes11Anterior.lead = dad.mes11Anterior.lead;
        mes11Anterior.agendados = dad.mes11Anterior.agendados;
        mes11Anterior.inscritos = dad.mes11Anterior.inscritos;
        mes11Anterior.leadRDStation = dad.mes11Anterior.leadRDStation;
        mes11Anterior.matriculados = dad.mes11Anterior.matriculados;
        mes11Anterior.aprovado = dad.mes11Anterior.aprovado;
        mes11Anterior.naoAprovado = dad.mes11Anterior.naoAprovado;
        mes11Anterior.naoCompareceu = dad.mes11Anterior.naoCompareceu;

        dashboard.filtro = filtro;
        dashboard.hoje = hoje;
        dashboard.mesAtual = mesAtual;
        dashboard.mes1Anterior = mes1Anterior;
        dashboard.mes2Anterior = mes2Anterior;
        dashboard.mes3Anterior = mes3Anterior;
        dashboard.mes4Anterior = mes4Anterior;
        dashboard.mes5Anterior = mes5Anterior;
        dashboard.mes6Anterior = mes6Anterior;
        dashboard.mes7Anterior = mes7Anterior;
        dashboard.mes8Anterior = mes8Anterior;
        dashboard.mes9Anterior = mes9Anterior;
        dashboard.mes10Anterior = mes10Anterior;
        dashboard.mes11Anterior = mes11Anterior;

        return of(dashboard);
      })
    );
  }

}
