// USA
export const locale = {
	lang: 'pt',
	data: {
		TRANSLATOR: {
			SELECT: 'Selecione sua linguagem',
		},
		MENU: {
			NEW: 'novo',
			ACTIONS: 'Ações',
			CREATE_POST: 'Criar novo Post',
			REPORTS: 'Relatórios',
			APPS: 'Apps',
			DASHBOARD: 'Painel de Controle',
			REGISTER: 'Inscritos'
		},
		AUTH: {
			GENERAL: {
				OR: 'Ou',
				SUBMIT_BUTTON: 'Enviar',
				NO_ACCOUNT: 'Não tem uma conta?',
				SIGNUP_BUTTON: 'Inscrever-se',
				FORGOT_BUTTON: 'Esqueceu sua senha?',
				BACK_BUTTON: 'Voltar',
				PRIVACY: 'Privacidade',
				LEGAL: 'Legal',
				CONTACT: 'Contato',
				MY_PROFILE: 'Meu Perfil',
			},
			LOGIN: {
				TITLE: 'Realize seu Login',
				BUTTON: 'Entrar',
			},
			LOGOUT: {
				BUTTON: 'Sair',
			},
			FORGOT: {
				TITLE: 'Esqueceu sua senha?',
				DESC: 'Insira seu e-mail para redefinir sua senha',
			},
			REGISTER: {
				TITLE: 'Inscrever-se',
				DESC: 'Insira seus dados para criar sua conta',
				SUCCESS: 'Sua conta foi registrada com sucesso. Por favor, use sua conta registrada para fazer o login.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Nome completo',
				PASSWORD: 'Senha',
				CONFIRM_PASSWORD: 'Confirme a Senha',
			},
			VALIDATION: {
				INVALID: '{{name}} não é válido',
				REQUIRED: '{{name}} é necessário',
				MIN_LENGTH: '{{name}} comprimento mínimo é {{min}}',
				AGREEMENT_REQUIRED: 'Aceitar termos e condições são obrigatórios',
				NOT_FOUND: 'O solicitado {{name}} não foi encontrado',
				INVALID_LOGIN: 'O Email ou CPF de login está incorreto'
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Contagem de registros selecionados: ',
				ALL: 'Todos',
				SUSPENDED: 'Suspenso',
				ACTIVE: 'Ativo',
				FILTER: 'Filtro',
				BY_STATUS: 'por Status',
				BY_TYPE: 'por Tipo',
				BUSINESS: 'Negócio',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Procurar',
				IN_ALL_FIELDS: 'em todos os campos'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Usuário',
				CUSTOMERS_LIST: 'Lista de Usuário',
				NEW_CUSTOMER: 'Novo Usuário',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Apagar Usuário',
					DESCRIPTION: 'Tem certeza que deseja excluir permanentemente este usuário?',
					WAIT_DESCRIPTION: 'O usuário está sendo excluído...',
					MESSAGE: 'Usuário foi deletado'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Excluir Usuário',
					DESCRIPTION: 'Tem certeza de excluir permanentemente os usuários selecionados?',
					WAIT_DESCRIPTION: 'Os usuários estão sendo excluídos...',
					MESSAGE: 'Usuários selecionados foram excluídos'
				},
				UPDATE_STATUS: {
					TITLE: 'O status foi atualizado para os usuário selecionados',
					MESSAGE: 'O status dos usuário selecionados foram atualizados com sucesso'
				},
				EDIT: {
					UPDATE_MESSAGE: 'O Usuário foi atualizado',
					ADD_MESSAGE: 'O Usuário foi criado'
				}
			}
		},
		CADASTROS: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Contagem de registros selecionados: ',
				ALL: 'Todos',
				SUSPENDED: 'Suspenso',
				ACTIVE: 'Ativo',
				FILTER: 'Filtro',
				BY_STATUS: 'por Status',
				BY_TYPE: 'por Tipo',
				BUSINESS: 'Negócio',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Procurar',
				IN_ALL_FIELDS: 'em todos os campos'
			},
			ECOMMERCE: 'eCommerce',
			ACCESS: {
				ACCESS: 'Acessos',
				ACCESS_LIST: 'Lista de Acessos',
				NEW_ACCESS: 'Novo Acesso',
				UPDATE_ACESSES: {
					TITLE: 'Atualizar Acessos',
					DESCRIPTION: 'Tem certeza que deseja realizar essa alteração?',
					WAIT_DESCRIPTION: 'Os acessos estão sendo atualizados...',
					MESSAGE: 'Os Acessos foram atualizados com sucesso!',
					FAILED: 'Falha ao atualizar os acessos. Por favor, tente novamente ou contate o administrador!'
				},
			},
			PROFILE: {
				PROFILE: 'Perfis',
				PROFILE_LIST: 'Lista de Perfis',
				NEW_PROFILE: 'Novo Perfil',
				DELETE_PROFILE_SIMPLE: {
					TITLE: 'Apagar Perfil',
					DESCRIPTION: 'Tem certeza que deseja excluir permanentemente este perfil?',
					WAIT_DESCRIPTION: 'O perfil está sendo excluído...',
					MESSAGE: 'Perfil foi deletado com sucesso!',
					FAILED: 'Falha ao deletar o perfil selecionado, tente novamente ou contate o administrador!'
				},
				DELETE_PROFILE_MULTY: {
					TITLE: 'Excluir Perfis',
					DESCRIPTION: 'Tem certeza de excluir permanentemente os perfis selecionados?',
					WAIT_DESCRIPTION: 'Os perfis estão sendo excluídos...',
					MESSAGE: 'Perfis selecionados foram excluídos'
				},
				UPDATE_PROFILE: {
					TITLE: 'O status foi atualizado para os usuário selecionados',
					MESSAGE: 'O status dos usuário selecionados foram atualizados com sucesso!'
				},
				EDIT: {
					UPDATE_MESSAGE: 'O Perfil foi atualizado com sucesso!',
					ADD_MESSAGE: 'O Perfil foi criado com sucesso!',
					FAILED_MESSAGE: 'Algo de errado aconteceu com a transação, por favor tente novamente mais tarde ou contate o administrador!'
				}
			},
			USERS: {
				USERS: 'Usuário',
				USERS_LIST: 'Lista de Usuários',
				NEW_USER: 'Novo Usuário',
				DELETE_USER_SIMPLE: {
					TITLE: 'Apagar Usuário',
					DESCRIPTION: 'Tem certeza que deseja excluir permanentemente este usuário?',
					WAIT_DESCRIPTION: 'O usuário está sendo excluído...',
					MESSAGE: 'Usuário foi deletado',
					FAILED: 'Falha ao deletar o usuário selecionado, tente novamente ou contate o administrador!'
				},
				DELETE_USER_MULTY: {
					TITLE: 'Excluir Usuário',
					DESCRIPTION: 'Tem certeza de excluir permanentemente os usuários selecionados?',
					WAIT_DESCRIPTION: 'Os usuários estão sendo excluídos...',
					MESSAGE: 'Usuários selecionados foram excluídos'
				},
				UPDATE_STATUS: {
					TITLE: 'Atualizar o status para os usuários selecionados',
					MESSAGE: 'O status dos usuários selecionados foram atualizados com sucesso!'
				},
				EDIT: {
					UPDATE_MESSAGE: 'O Usuário foi atualizado',
					ADD_MESSAGE: 'O Usuário foi criado',
					FAILED_MESSAGE: 'Algo de errado aconteceu com a transação, por favor tente novamente mais tarde ou contate o administrador!'
				}
			},
			SCHEDULE: {
				SCHEDULE: 'Agendamento',
				EDIT: {
					UPDATE_MESSAGE: 'O Agendamento foi atualizado com sucesso!',
					FAILED_MESSAGE: 'Algo de errado aconteceu com a transação, por favor tente novamente mais tarde ou contate o administrador!'
				}
			},
			AGREEMENT: {
				AGREEMENT: 'Convênio',
				AGREEMENT_LIST: 'Lista de Convênios',
				NEW_AGREEMENT: 'Novo Convênio',
				DELETE_AGREEMENT_SIMPLE: {
					TITLE: 'Apagar Convênio',
					DESCRIPTION: 'Tem certeza que deseja excluir permanentemente este convênio?',
					WAIT_DESCRIPTION: 'O convênio está sendo excluído...',
					MESSAGE: 'Convênio foi deletado com sucesso!',
					FAILED: 'Falha ao deletar o convênio selecionado, tente novamente ou contate o administrador!'
				},
				DELETE_AGREEMENT_MULTY: {
					TITLE: 'Excluir Convênio',
					DESCRIPTION: 'Tem certeza de excluir permanentemente os convênios selecionados?',
					WAIT_DESCRIPTION: 'Os convênios estão sendo excluídos...',
					MESSAGE: 'Convênios selecionados foram excluídos com sucesso!'
				},
				UPDATE_STATUS: {
					TITLE: 'Atualizar o status dos convênios selecionados',
					MESSAGE: 'O status dos convênios selecionados foram atualizados com sucesso!'
				},
				EDIT: {
					UPDATE_MESSAGE: 'O Convênio foi atualizado com sucesso!',
					ADD_MESSAGE: 'O Convênio foi criado com sucesso!',
					FAILED_MESSAGE: 'Algo de errado aconteceu com a transação, por favor tente novamente mais tarde ou contate o administrador!'
				}
			},
			COURSE: {
				COURSE: 'Cursos',
				COURSE_LIST: 'Lista de Cursos',
				NEW_COURSE: 'Novo Curso',
				DELETE_COURSE_SIMPLE: {
					TITLE: 'Apagar Curso',
					DESCRIPTION: 'Tem certeza que deseja excluir permanentemente este curso?',
					WAIT_DESCRIPTION: 'O curso está sendo excluído...',
					MESSAGE: 'Curso foi deletado com sucesso!',
					FAILED: 'Falha ao deletar o curso selecionado, tente novamente ou contate o administrador!'
				},
				UPDATE_STATUS: {
					TITLE: 'Atualizar o status para os cursos selecionados',
					MESSAGE: 'O status dos cursos selecionados foram atualizados com sucesso!'
				},
				EDIT: {
					UPDATE_MESSAGE: 'O Curso foi atualizado',
					ADD_MESSAGE: 'O Curso foi criado',
					FAILED_MESSAGE: 'Algo de errado aconteceu com a transação, por favor tente novamente mais tarde ou contate o administrador!'
				}
			},
			SELECTIVE_PROCESS: {
				SELECTIVE_PROCESS: 'Processo Seletivo',
				SELECTIVE_PROCESS_LIST: 'Lista de Processos Seletivos',
				NEW_SELECTIVE_PROCESS: 'Novo Processo Seletivo',
				DELETE_SELECTIVE_PROCESS_SIMPLE: {
					TITLE: 'Apagar Processo Seletivo',
					DESCRIPTION: 'Tem certeza que deseja excluir permanentemente este processo seletivo?',
					WAIT_DESCRIPTION: 'O processo seletivo está sendo excluído...',
					MESSAGE: 'Processo Seletivo foi deletado com sucesso!',
					FAILED: 'Falha ao deletar o processo seletivo selecionado, tente novamente ou contate o administrador!'
				},
				UPDATE_STATUS: {
					TITLE: 'Atualizar o status para os processos seletivos selecionados',
					MESSAGE: 'O status dos processos seletivos selecionados foram atualizados com sucesso!'
				},
				EDIT: {
					UPDATE_MESSAGE: 'O Processo Seletivo foi atualizado',
					ADD_MESSAGE: 'O Processo Seletivo foi criado',
					FAILED_MESSAGE: 'Algo de errado aconteceu com a transação, por favor tente novamente mais tarde ou contate o administrador!'
				}
			},
			REGISTER: {
				REGISTER: 'Inscritos',
				REGISTER_LIST: 'Lista de Inscritos',
				NEW_REGISTER: 'Nova Inscrição',
				DELETE_REGISTER_SIMPLE: {
					TITLE: 'Apagar Inscrição',
					DESCRIPTION: 'Tem certeza que deseja excluir permanentemente esta inscrição?',
					WAIT_DESCRIPTION: 'A inscrição está sendo excluída...',
					MESSAGE: 'Inscrição foi deletado com sucesso!',
					FAILED: 'Falha ao deletar a inscrição selecionada, tente novamente ou contate o administrador!'
				},
				DELETE_REGISTER_MULTY: {
					TITLE: 'Excluir Inscrições',
					DESCRIPTION: 'Tem certeza de excluir permanentemente as inscrições selecionadas?',
					WAIT_DESCRIPTION: 'As inscrições estão sendo excluídas...',
					MESSAGE: 'Inscritos selecionados foram excluídos com sucesso!'
				},
				UPDATE_STATUS: {
					TITLE: 'Atualizar o status para os inscritos selecionados',
					MESSAGE: 'O status dos inscritos selecionados foram atualizados com sucesso!'
				},
				EDIT: {
					UPDATE_MESSAGE: 'O Inscrição foi atualizada com sucesso!',
					ADD_MESSAGE: 'O Inscrição foi feita com sucesso!',
					FAILED_MESSAGE: 'Algo de errado aconteceu com a transação, por favor tente novamente mais tarde ou contate o administrador!'
				},
				UPDATE_NOTE: {
					ADD_MESSAGE: 'A Nota foi lançada com sucesso!',
					FAILED_MESSAGE: 'Algo de errado aconteceu com a transação, por favor tente novamente mais tarde ou contate o administrador!'
				},
				ENROLL_STUDENT: {
					TITLE: 'Matricular Inscrito',
					DESCRIPTION: 'Tem certeza que deseja matricular este inscrito?',
					WAIT_DESCRIPTION: 'A matricula está sendo executada...',
					MESSAGE: 'A Matricula foi realizada com sucesso!',
					FAILED: 'Falha ao matricular o aluno selecionado, tente novamente ou contate o administrador!'
				},
			},
		}
	}
};
