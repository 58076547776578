import { Estado } from "./Estado";

export class Cidade {
    id: number;    
    nome: string;
    idEstado: number;

    estado: Estado;

	clear() {
        this.id = 0;
		this.nome = '';
        this.idEstado = 0;

        this.estado = new Estado();
        this.estado.clear();
	}
}