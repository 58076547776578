import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MenuConfigService } from '../menu-config.service';
import { ClassInitService } from '../class-init.service';
import * as objectPath from 'object-path';
import { LayoutConfigService } from '../layout-config.service';
import { TokenStorage } from '../../auth/token-storage.service';

@Injectable()
export class MenuAsideService {
	classes: string;
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject([]);

	isDropdown: number = 0;
	dropdownTimeout: number;
	isScrollable: number = 0;

	constructor(
		private menuConfigService: MenuConfigService,
		private classInitService: ClassInitService,
		private layoutConfigService: LayoutConfigService,
		private tokenStorage: TokenStorage
	) {
		// get menu list
		this.menuConfigService.onMenuUpdated$.subscribe(model => {

			let usuario = tokenStorage.getUsuario();
			if (usuario != null) {
				for (var item = 0; item < model.config.aside.items.length; item++) {
					let contains1 = false;
					if (model.config.aside.items[item].submenu != undefined) {
						for (var i = 0; i < model.config.aside.items[item].submenu.length; i++) {
							let contains2 = false;
							usuario.lstTelasPerfis.forEach(telas => {
								if (model.config.aside.items[item].submenu[i].title == telas.descricao && telas.visualizar) {
									contains2 = true;
								}
							});

							if (!contains2) {
								model.config.aside.items[item].submenu.splice(i, 1);
								i = i - 1;
							}
						};
					} else if (model.config.aside.items[item].title != undefined) {
						usuario.lstTelasPerfis.forEach(telas => {
							if (model.config.aside.items[item].title == telas.descricao && telas.visualizar) {
								contains1 = true;
							}
						});

						if (!contains1) {
							model.config.aside.items.splice(item, 1);
							item = item - 1;
						}
					}
				};

				for (var item = 0; item < model.config.aside.items.length; item++) {
					if (model.config.aside.items[item].submenu != undefined) {
						if (model.config.aside.items[item].submenu.length == 0) {
							model.config.aside.items.splice(item, 1);
							item = item - 1;

							if (model.config.aside.items[item].section != undefined) {
								model.config.aside.items.splice(item, 1);
								item = item - 1;
							}
						}
					}
				}
			}

			setTimeout(() =>
				this.menuList$.next(objectPath.get(model.config, 'aside.items'))
			);
		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(config => {
			if (objectPath.get(config, 'config.aside.left.fixed')) {
				this.isScrollable = 1;
				this.isDropdown = 0;
			}

			// tslint:disable-next-line:max-line-length
			if (!objectPath.get(config, 'config.aside.left.fixed') && !objectPath.get(config, 'config.menu.aside.desktop_and_mobile.submenu.accordion')) {
				this.isScrollable = 0;
				this.isDropdown = 1;
				this.dropdownTimeout = objectPath.get(config, 'config.menu.aside.desktop_and_mobile.submenu.dropdown.hover_timeout');
			}
		});
	}
}
