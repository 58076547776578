import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { InscricaoService } from '../../../../../pages/builder/service/inscricao.service';
import { Dashboard } from '../../../../../pages/components/cadastros/_core_cadastros/models/Dashboard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../../../pages/components/cadastros/_core_cadastros/utils/date.adapter';
import Swal from 'sweetalert2';
import { UtilsService } from '../../../../../pages/components/cadastros/_core_cadastros/services/utils.service';
import { Origem } from '../../../../../pages/components/cadastros/_core_cadastros/models/Origem';

@Component({
    selector: 'm-doughnut-chart',
    templateUrl: './doughnut-chart.component.html',
    styleUrls: ['./doughnut-chart.component.scss'],
    providers: [
      {
        provide: DateAdapter, useClass: AppDateAdapter
      },
      {
        provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
      },
      {
        provide: MAT_DATE_LOCALE, useValue: 'pt-br'
      }
    ]
})
export class DoughnutChartComponent implements OnInit {
    // Doughnut
    dashForm: FormGroup;
    dashboard: Dashboard = new Dashboard();
    public doughnutChartLabels: string[] = ['Leads', 'Inscritos', 'Agendados', 'NaoAprov.', 'Aprovado', 'NaoComp.']; //, 'Matrículados'
    public doughnutChartData: number[] = [0, 0, 0, 0, 0, 0];
    public doughnutChartType: string = 'pie';

    leads: number = 0;
    agendados: number = 0;
    inscritos: number = 0;
    aprovado: number = 0;
    naoAprovado: number = 0;
    naoCompareceu: number = 0;  
    origemList: Origem[] = [];

    hoje: Date = new Date;

    Source: Object;

    constructor(private inscricaoService: InscricaoService,
        private fb: FormBuilder,
        private utilsService: UtilsService,
        private cd: ChangeDetectorRef) {
        this.buscarDadosDashboard();
        this.carregaOrigens();
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.dashForm = this.fb.group({
            dataInicio: [this.hoje, Validators.required],
            dataFim: [this.hoje, Validators.required],
            origem: [0, Validators.required],
        });
    }

    carregaOrigens(){
      this.utilsService.listarOrigens().subscribe(data => {
        this.origemList = data;
      });
    }

    buscarDadosDashboard(dataInicio ?: Date, dataFinal ?: Date, origem ?: number) {
        this.inscricaoService.buscarDadosDashboard(dataInicio, dataFinal, origem).subscribe(data => {
            this.dashboard = data;
            this.montaGrafico();
        });
    }

    montaGrafico() {
        this.doughnutChartData = [];
        this.doughnutChartLabels = [];

        this.doughnutChartLabels.push('Leads');
        this.doughnutChartLabels.push('Inscritos');
        this.doughnutChartLabels.push('Agendados');
        this.doughnutChartLabels.push('NaoAprov.');
        this.doughnutChartLabels.push('Aprovados');
        this.doughnutChartLabels.push('NaoComp.');
        this.leads = this.dashboard.filtro.lead + this.dashboard.filtro.leadRDStation;
        this.inscritos = this.dashboard.filtro.inscritos;
        this.agendados = this.dashboard.filtro.agendados;
        this.aprovado = this.dashboard.filtro.aprovado;
        this.naoAprovado = this.dashboard.filtro.naoAprovado;
        this.naoCompareceu = this.dashboard.filtro.naoCompareceu;
        this.doughnutChartData.push(this.leads);
        this.doughnutChartData.push(this.inscritos);
        this.doughnutChartData.push(this.agendados);
        this.doughnutChartData.push(this.naoAprovado);
        this.doughnutChartData.push(this.aprovado);
        this.doughnutChartData.push(this.naoCompareceu);
        this.doughnutChartType = 'pie';

        this.cd.markForCheck();
    }

    onSubmit() {
      const controls = this.dashForm.controls;
      
      // stop here if form is invalid
      if (this.dashForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
  
              Swal.fire({
                  type: 'error',
                  title: 'Aviso!',
                  text: 'Data incorreta, por favor preencha a data corretamente.',
              });      
        return;
      }
  
      var dataInicial = new Date;
      var dataFinal = new Date;
      dataInicial = controls['dataInicio'].value;
      dataFinal = controls['dataFim'].value;
      if (dataInicial > dataFinal) {
        controls['dataInicio'].markAsTouched();
  
              Swal.fire({
                  type: 'error',
                  title: 'Aviso!',
                  text: 'Data Inicial não pode ser maior que a Data Final. Por favor, digite uma data Inicial menor!',
              });    
        return;
      }
  
      var origem = controls['origem'].value;
      this.buscarDadosDashboard(dataInicial, dataFinal, +origem);      
    }

    //constructor ( private inscricaoService: InscricaoService) { 
    // 	this.Source = 
    // 	{
    //         "chart": {
    //         "caption": "Meta de hoje",
    //         "lowerLimit": "0",
    //         "upperLimit": "100",
    //         "showValue": "1",
    //         "numberSuffix": "%",
    //         "theme": "fusion",
    //         "showToolTip": "0"
    //     },
    //     // Gauge Data
    //     "colorRange": {
    //         "color": [{
    //             "minValue": "0",
    //             "maxValue": "50",
    //             "code": "#F2726F"
    //         }, {
    //             "minValue": "50",
    //             "maxValue": "75",
    //             "code": "#FFC533"
    //         }, {
    //             "minValue": "75",
    //             "maxValue": "100",
    //             "code": "#62B58F"
    //         }]
    //     },
    //     "dials": {
    //         "dial": [{
    //             "value": "81"
    //         }]
    //     }
    // }; // end of this.dataSource
    //}

    // // events
    // chartClicked (e: any): void {
    // }

    // chartHovered (e: any): void {
    // }
}
