import { HistoricoStatus } from "./HistoricoStatus";

export class Dashboard  {
    filtro         : HistoricoStatus;
    hoje           : HistoricoStatus;
    mesAtual       : HistoricoStatus;
    mes1Anterior   : HistoricoStatus;
    mes2Anterior   : HistoricoStatus;
    mes3Anterior   : HistoricoStatus;
    mes4Anterior   : HistoricoStatus;
    mes5Anterior   : HistoricoStatus;
    mes6Anterior   : HistoricoStatus;
    mes7Anterior   : HistoricoStatus;
    mes8Anterior   : HistoricoStatus;
    mes9Anterior   : HistoricoStatus;
    mes10Anterior  : HistoricoStatus;
    mes11Anterior  : HistoricoStatus;        
}