import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { InscricaoService } from '../../../../../pages/builder/service/inscricao.service';
import { Dashboard } from '../../../../../pages/components/cadastros/_core_cadastros/models/Dashboard';


const nomesMeses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
	"Jul", "Ago", "Set", "Out", "Nov", "Dez"];

@Component({
	selector: 'm-bar-chart',
	templateUrl: './bar-chart.component.html',
	styleUrls: ['./bar-chart.component.scss']
})

export class BarChartComponent implements OnInit {

	dashboard: Dashboard = new Dashboard();

	public barChartOptions: any = {
		scaleShowVerticalLines: false,
		responsive: true
	};

	public barChartLabels: string[] = ['Tes', 'Tea', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', 'Jan', 'Fev', 'Mar', 'Abr'];
	public barChartType: string = 'bar';
	public barChartLegend: boolean = true;

	dataLeads = [];
	dataInscritos = [];
	dataAgendados = [];
	dataAprovados = [];
	dataNaoAprov = [];
	dataNaoComp = [];

	public barChartData: any[] = [
		{ data: [65, 59, 80, 81, 56, 55, 40, 65, 78, 90, 80, 40], label: 'Leads' },
		{ data: [60, 48, 74, 72, 43, 50, 36, 60, 58, 80, 85, 50], label: 'Inscritos' },
		{ data: [54, 43, 65, 70, 41, 47, 32, 55, 55, 74, 70, 30], label: 'Agendados' },
		
		{ data: [60, 48, 74, 72, 43, 50, 36, 60, 58, 80, 85, 50], label: 'NaoAprov.' },
		{ data: [65, 59, 80, 81, 56, 55, 40, 65, 78, 90, 80, 40], label: 'Aprovado' },
		{ data: [54, 43, 65, 70, 41, 47, 32, 55, 55, 74, 70, 30], label: 'NaoComp.' },
		// { data: [36, 40, 40, 51, 36, 27, 30, 47, 51, 60], label: 'Matrículados' }
	];

	// Source: Object;

	constructor(private inscricaoService: InscricaoService,
		private cd: ChangeDetectorRef) {
		this.barChartLabels = [];
		let d = new Date();
		let i = 0;
		let numb = 0;
		for (i = 0; i < 12; i++) {
			if (d.getMonth() - i < 0) {
				if (d.getMonth() - i == -1)
					numb = 11;
				else
					numb = numb - 1;
			}
			else
				numb = d.getMonth() - i;

			// console.log(nomesMeses[numb]);
			this.barChartLabels.push(nomesMeses[numb]);
			this.cd.markForCheck();
		}

		this.buscarDadosDashboard();
	}

	ngOnInit() {
	}

	buscarDadosDashboard() {
		this.inscricaoService.buscarDadosDashboard().subscribe(data => {
			this.dashboard = data;
			this.montaGrafico();
		});
	}

	montaGrafico() {
		this.barChartData = [];
		this.dataLeads.push(this.dashboard.mesAtual.lead + this.dashboard.mesAtual.leadRDStation);
		this.dataAgendados.push(this.dashboard.mesAtual.agendados);
		this.dataInscritos.push(this.dashboard.mesAtual.inscritos);
		this.dataNaoAprov.push(this.dashboard.mesAtual.naoAprovado);
		this.dataAprovados.push(this.dashboard.mesAtual.aprovado);
		this.dataNaoComp.push(this.dashboard.mesAtual.naoCompareceu);

		this.dataLeads.push(this.dashboard.mes1Anterior.lead + this.dashboard.mes1Anterior.leadRDStation);
		this.dataAgendados.push(this.dashboard.mes1Anterior.agendados);
		this.dataInscritos.push(this.dashboard.mes1Anterior.inscritos);
		this.dataNaoAprov.push(this.dashboard.mes1Anterior.naoAprovado);
		this.dataAprovados.push(this.dashboard.mes1Anterior.aprovado);
		this.dataNaoComp.push(this.dashboard.mes1Anterior.naoCompareceu);

		this.dataLeads.push(this.dashboard.mes2Anterior.lead + this.dashboard.mes2Anterior.leadRDStation);
		this.dataAgendados.push(this.dashboard.mes2Anterior.agendados);
		this.dataInscritos.push(this.dashboard.mes2Anterior.inscritos);
		this.dataNaoAprov.push(this.dashboard.mes2Anterior.naoAprovado);
		this.dataAprovados.push(this.dashboard.mes2Anterior.aprovado);
		this.dataNaoComp.push(this.dashboard.mes2Anterior.naoCompareceu);

		this.dataLeads.push(this.dashboard.mes3Anterior.lead + this.dashboard.mes3Anterior.leadRDStation);
		this.dataAgendados.push(this.dashboard.mes3Anterior.agendados);
		this.dataInscritos.push(this.dashboard.mes3Anterior.inscritos);
		this.dataNaoAprov.push(this.dashboard.mes3Anterior.naoAprovado);
		this.dataAprovados.push(this.dashboard.mes3Anterior.aprovado);
		this.dataNaoComp.push(this.dashboard.mes3Anterior.naoCompareceu);

		this.dataLeads.push(this.dashboard.mes4Anterior.lead + this.dashboard.mes4Anterior.leadRDStation);
		this.dataAgendados.push(this.dashboard.mes4Anterior.agendados);
		this.dataInscritos.push(this.dashboard.mes4Anterior.inscritos);
		this.dataNaoAprov.push(this.dashboard.mes4Anterior.naoAprovado);
		this.dataAprovados.push(this.dashboard.mes4Anterior.aprovado);
		this.dataNaoComp.push(this.dashboard.mes4Anterior.naoCompareceu);

		this.dataLeads.push(this.dashboard.mes5Anterior.lead + this.dashboard.mes5Anterior.leadRDStation);
		this.dataAgendados.push(this.dashboard.mes5Anterior.agendados);
		this.dataInscritos.push(this.dashboard.mes5Anterior.inscritos);
		this.dataNaoAprov.push(this.dashboard.mes5Anterior.naoAprovado);
		this.dataAprovados.push(this.dashboard.mes5Anterior.aprovado);
		this.dataNaoComp.push(this.dashboard.mes5Anterior.naoCompareceu);

		this.dataLeads.push(this.dashboard.mes6Anterior.lead + this.dashboard.mes6Anterior.leadRDStation);
		this.dataAgendados.push(this.dashboard.mes6Anterior.agendados);
		this.dataInscritos.push(this.dashboard.mes6Anterior.inscritos);
		this.dataNaoAprov.push(this.dashboard.mes6Anterior.naoAprovado);
		this.dataAprovados.push(this.dashboard.mes6Anterior.aprovado);
		this.dataNaoComp.push(this.dashboard.mes6Anterior.naoCompareceu);

		this.dataLeads.push(this.dashboard.mes7Anterior.lead + this.dashboard.mes7Anterior.leadRDStation);
		this.dataAgendados.push(this.dashboard.mes7Anterior.agendados);
		this.dataInscritos.push(this.dashboard.mes7Anterior.inscritos);
		this.dataNaoAprov.push(this.dashboard.mes7Anterior.naoAprovado);
		this.dataAprovados.push(this.dashboard.mes7Anterior.aprovado);
		this.dataNaoComp.push(this.dashboard.mes7Anterior.naoCompareceu);

		this.dataLeads.push(this.dashboard.mes8Anterior.lead + this.dashboard.mes8Anterior.leadRDStation);
		this.dataAgendados.push(this.dashboard.mes8Anterior.agendados);
		this.dataInscritos.push(this.dashboard.mes8Anterior.inscritos);
		this.dataNaoAprov.push(this.dashboard.mes8Anterior.naoAprovado);
		this.dataAprovados.push(this.dashboard.mes8Anterior.aprovado);
		this.dataNaoComp.push(this.dashboard.mes8Anterior.naoCompareceu);

		this.dataLeads.push(this.dashboard.mes9Anterior.lead + this.dashboard.mes9Anterior.leadRDStation);
		this.dataAgendados.push(this.dashboard.mes9Anterior.agendados);
		this.dataInscritos.push(this.dashboard.mes9Anterior.inscritos);
		this.dataNaoAprov.push(this.dashboard.mes9Anterior.naoAprovado);
		this.dataAprovados.push(this.dashboard.mes9Anterior.aprovado);
		this.dataNaoComp.push(this.dashboard.mes9Anterior.naoCompareceu);

		this.dataLeads.push(this.dashboard.mes10Anterior.lead + this.dashboard.mes10Anterior.leadRDStation);
		this.dataAgendados.push(this.dashboard.mes10Anterior.agendados);
		this.dataInscritos.push(this.dashboard.mes10Anterior.inscritos);
		this.dataNaoAprov.push(this.dashboard.mes10Anterior.naoAprovado);
		this.dataAprovados.push(this.dashboard.mes10Anterior.aprovado);
		this.dataNaoComp.push(this.dashboard.mes10Anterior.naoCompareceu);

		this.dataLeads.push(this.dashboard.mes11Anterior.lead + this.dashboard.mes11Anterior.leadRDStation);
		this.dataAgendados.push(this.dashboard.mes11Anterior.agendados);
		this.dataInscritos.push(this.dashboard.mes11Anterior.inscritos);
		this.dataNaoAprov.push(this.dashboard.mes11Anterior.naoAprovado);
		this.dataAprovados.push(this.dashboard.mes11Anterior.aprovado);
		this.dataNaoComp.push(this.dashboard.mes11Anterior.naoCompareceu);


		this.barChartData.push({ data: this.dataLeads, label: 'Leads' });
		this.barChartData.push({ data: this.dataInscritos, label: 'Inscritos' });
		this.barChartData.push({ data: this.dataAgendados, label: 'Agendados' });
		this.barChartData.push({ data: this.dataNaoAprov, label: 'NaoAprov' });
		this.barChartData.push({ data: this.dataAprovados, label: 'Aprovados' });
		this.barChartData.push({ data: this.dataNaoComp, label: 'NaoComp' });

		this.barChartType = 'bar';

		this.cd.markForCheck();
	}


	// this.Source = {
	// 	"chart": {
	// 		"caption": "Total de alunos",
	// 		"subcaption": "dos ultimos 12 meses",
	// 		"xaxisname": "Meses",
	// 		"yaxisname": "Total em números",
	// 		"formatnumberscale": "1",
	// 		"plottooltext": "<b>$dataValue</b> alunos no mês de <b>$seriesName</b>",
	// 		"theme": "fusion",
	// 		"drawcrossline": "1"
	// 	},
	// 	"categories": [
	// 		{
	// 			"category": [
	// 				{
	// 					"label": "Mar"
	// 				},
	// 				{
	// 					"label": "Abr"
	// 				},
	// 				{
	// 					"label": "Mai"
	// 				},
	// 				{
	// 					"label": "Jun"
	// 				},
	// 				{
	// 					"label": "Jul"
	// 				},
	// 				{
	// 					"label": "Ago"
	// 				},
	// 				{
	// 					"label": "Set"
	// 				},
	// 				{
	// 					"label": "Out"
	// 				},
	// 				{
	// 					"label": "Nov"
	// 				},
	// 				{
	// 					"label": "Dez"
	// 				},
	// 				{
	// 					"label": "Jan"
	// 				},
	// 				{
	// 					"label": "Fev"
	// 				}
	// 			]
	// 		}
	// 	],
	// 	"dataset": [
	// 		{
	// 			"seriesname": "Leads",
	// 			"data": [
	// 				{
	// 					"value": "65"
	// 				},
	// 				{
	// 					"value": "59"
	// 				},
	// 				{
	// 					"value": "80"
	// 				},
	// 				{
	// 					"value": "81"
	// 				},
	// 				{
	// 					"value": "56"
	// 				},
	// 				{
	// 					"value": "55"
	// 				},
	// 				{
	// 					"value": "40"
	// 				},
	// 				{
	// 					"value": "65"
	// 				},
	// 				{
	// 					"value": "78"
	// 				},
	// 				{
	// 					"value": "90"
	// 				},
	// 				{
	// 					"value": "70"
	// 				},
	// 				{
	// 					"value": "99"
	// 				}
	// 			]
	// 		},
	// 		{
	// 			"seriesname": "Inscritos",
	// 			"data": [
	// 				{
	// 					"value": "60"
	// 				},
	// 				{
	// 					"value": "48"
	// 				},
	// 				{
	// 					"value": "74"
	// 				},
	// 				{
	// 					"value": "72"
	// 				},
	// 				{
	// 					"value": "43"
	// 				},
	// 				{
	// 					"value": "50"
	// 				},
	// 				{
	// 					"value": "36"
	// 				},
	// 				{
	// 					"value": "60"
	// 				},
	// 				{
	// 					"value": "58"
	// 				},
	// 				{
	// 					"value": "80"
	// 				},
	// 				{
	// 					"value": "62"
	// 				},
	// 				{
	// 					"value": "88"
	// 				}
	// 			]
	// 		},
	// 		{
	// 			"seriesname": "Agendados",
	// 			"data": [
	// 				{
	// 					"value": "54"
	// 				},
	// 				{
	// 					"value": "43"
	// 				},
	// 				{
	// 					"value": "65"
	// 				},
	// 				{
	// 					"value": "70"
	// 				},
	// 				{
	// 					"value": "43"
	// 				},
	// 				{
	// 					"value": "41"
	// 				},
	// 				{
	// 					"value": "47"
	// 				},
	// 				{
	// 					"value": "32"
	// 				},
	// 				{
	// 					"value": "55"
	// 				},
	// 				{
	// 					"value": "55"
	// 				},
	// 				{
	// 					"value": "74"
	// 				},
	// 				{
	// 					"value": "82"
	// 				}
	// 			]
	// 		},
	// 		{
	// 			"seriesname": "Matrículados",
	// 			"data": [
	// 				{
	// 					"value": "36"
	// 				},
	// 				{
	// 					"value": "40"
	// 				},
	// 				{
	// 					"value": "40"
	// 				},
	// 				{
	// 					"value": "51"
	// 				},
	// 				{
	// 					"value": "36"
	// 				},
	// 				{
	// 					"value": "27"
	// 				},
	// 				{
	// 					"value": "30"
	// 				},
	// 				{
	// 					"value": "47"
	// 				},
	// 				{
	// 					"value": "51"
	// 				},
	// 				{
	// 					"value": "60"
	// 				},
	// 				{
	// 					"value": "59"
	// 				},
	// 				{
	// 					"value": "76"
	// 				}
	// 			]
	// 		}
	// 	]
	// };

	//--------------------------------------

	// events
	// chartClicked(e: any): void {
	// }

	// chartHovered(e: any): void {
	// }

}
