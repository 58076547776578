import { Pais } from "./Pais";

export class Estado {
    id: number;    
    nome: string;
    uf: string;
    idPais: number;
    
    pais: Pais;

	clear() {
        this.id = 0;
        this.nome = '';
        this.uf = '';
        this.idPais = 0;

        this.pais = new Pais();
        this.pais.clear();
	}
}