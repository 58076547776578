import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { InscricaoService } from '../../../../../pages/builder/service/inscricao.service';
import { Dashboard } from '../../../../../pages/components/cadastros/_core_cadastros/models/Dashboard';

@Component({
  selector: 'm-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss']
})
export class StatComponent implements OnInit {

  dashboard: Dashboard = new Dashboard();
  leads: number = 0;
  agendados: number = 0;
  inscritos: number = 0;
  aprovado: number = 0;
  naoAprovado: number = 0;
  naoCompareceu: number = 0;  

  constructor(private inscricaoService: InscricaoService,
		private cd: ChangeDetectorRef) {
    this.buscarDadosDashboard();	
  }

  ngOnInit() {
  }
    
  buscarDadosDashboard(){
      this.inscricaoService.buscarDadosDashboard().subscribe(data => {
          this.dashboard = data;
          this.montaGrafico();
  });
  }

  montaGrafico(){
    this.leads = this.dashboard.hoje.lead + this.dashboard.hoje.leadRDStation;
    this.agendados = this.dashboard.hoje.agendados;
    this.inscritos = this.dashboard.hoje.inscritos;
    this.aprovado = this.dashboard.hoje.aprovado;
    this.naoAprovado = this.dashboard.hoje.naoAprovado;
    this.naoCompareceu = this.dashboard.hoje.naoCompareceu;
    
		this.cd.markForCheck();
  }
}
