export class CarsDb {
	public static cars: any = [
		{
			'id': 1,
			'cModel': 'juliomanoeloliverdamata-82@lonza.com',
			'cManufacture': 'Julio Manoel Oliver da Mata',
			'cModelYear': 2004,
			'cMileage': 116879,
			// tslint:disable-next-line:max-line-length
			'cDescription': `Administração`,
			'cColor': '041 996386193',
			'cPrice': 18347,
			'cCondition': 1,
			'createdDate': '09/30/2017',
			'cStatus': 0,
			'cVINCode': '57388391886',
		}, {
			'id': 2,
			'cModel': 'suzana.a@yahoo.com.br',
			'cManufacture': 'Suzana Aristides',
			'cModelYear': 1984,
			'cMileage': 99515,
			// tslint:disable-next-line:max-line-length
			'cDescription': `Segurança do Trabalho`,
			'cColor': '048 992342375',
			'cPrice': 165956,
			'cCondition': 0,
			'createdDate': '03/22/2018',
			'cStatus': 1,
			'cVINCode': '53291238010'
		}, {
			'id': 3,
			'cModel': 'ana.fur@hotmail.com',
			'cManufacture': 'Ana Furtado',
			'cModelYear': 1993,
			'cMileage': 138027,
			// tslint:disable-next-line:max-line-length
			'cDescription': `Açúcar e Álcool`,
			'cColor': '044 999653234',
			'cPrice': 45684,
			'cCondition': 0,
			'createdDate': '03/06/2018',
			'cStatus': 2,
			'cVINCode': '05331866093'
		}, {
			'id': 4,
			'cModel': 'bbenjamincesarfigueiredo@crsilvadesign.com',
			'cManufacture': 'Benjamin César Figueiredo',
			'cModelYear': 2004,
			'cMileage': 183068,
			// tslint:disable-next-line:max-line-length
			'cDescription': `Podologia`,
			'cColor': '043 992664350',
			'cPrice': 95410,
			'cCondition': 1,
			'createdDate': '02/03/2018',
			'cStatus': 2,
			'cVINCode': '02860879013'
		}, {
			'id': 5,
			'cModel': 'hheitor@oralcamp.com.br',
			'cManufacture': 'Heitor Vinicius',
			'cModelYear': 1997,
			'cMileage': 74884,
			// tslint:disable-next-line:max-line-length
			'cDescription': `Farmácia e Manipulação`,
			'cColor': '045 984294164',
			'cPrice': 24796,
			'cCondition': 1,
			'createdDate': '08/13/2017',
			'cStatus': 0,
			'cVINCode': '08195727050'
		}, {
			'id': 6,
			'cModel': 'viniciusvicenteporto@oralcamp.com.br',
			'cManufacture': 'Vicente Porto',
			'cModelYear': 2009,
			'cMileage': 194846,
			// tslint:disable-next-line:max-line-length
			'cDescription': `Açúcar e Álcool`,
			'cColor': '042 984983689',
			'cPrice': 30521,
			'cCondition': 1,
			'createdDate': '01/27/2018',
			'cStatus': 0,
			'cVINCode': '26129741057'
		}, {
			'id': 7,
			'cModel': 'caua.paz@hotmail.com.br',
			'cManufacture': 'Cauã da Paz',
			'cModelYear': 2008,
			'cMileage': 164124,
			// tslint:disable-next-line:max-line-length
			'cDescription': `Segurança do Trabalho`,
			'cColor': '044 992876730',
			'cPrice': 196247,
			'cCondition': 1,
			'createdDate': '09/28/2017',
			'cStatus': 1,
			'cVINCode': '64085347080'
		}, {
			'id': 8,
			'cModel': 'martingaeljoaquimoliveira-92@foxtech.com.br',
			'cManufacture': 'Martin Gael Joaquim Oliveira',
			'cModelYear': 2002,
			'cMileage': 57410,
			// tslint:disable-next-line:max-line-length
			'cDescription': `Estética`,
			'cColor': '044 996661188',
			'cPrice': 185775,
			'cCondition': 1,
			'createdDate': '11/15/2017',
			'cStatus': 0,
			'cVINCode': '86222663036'
		}, {
			'id': 9,
			'cModel': 'bbeneditoraimundorodrigues@abdalathomaz.adv.br',
			'cManufacture': 'Benedito Raimundo Rodrigues',
			'cModelYear': 1993,
			'cMileage': 4444,
			// tslint:disable-next-line:max-line-length
			'cDescription': `Edificações`,
			'cColor': '041 991289908',
			'cPrice': 171898,
			'cCondition': 0,
			'createdDate': '12/24/2017',
			'cStatus': 1,
			'cVINCode': '70651841020'
		}, {
			'id': 10,
			'cModel': 'cauegiovannipires_@moyageorges.com.br',
			'cManufacture': 'Cauê Giovanni Pires',
			'cModelYear': 1999,
			'cMileage': 195310,
			// tslint:disable-next-line:max-line-length
			'cDescription': `Marketing`,
			'cColor': '041 991993534',
			'cPrice': 25764,
			'cCondition': 0,
			'createdDate': '08/30/2017',
			'cStatus': 1,
			'cVINCode': '83400571032'
		}, {
			'id': 11,
			'cModel': 'guilhermelorenzotiagorezende_@pisbrasil.com.br',
			'cManufacture': 'Guilherme Lorenzo Tiago Rezende',
			'cModelYear': 2012,
			'cMileage': 170862,
			// tslint:disable-next-line:max-line-length
			'cDescription': `Saúde Pública`,
			'cColor': '048 992739329',
			'cPrice': 80588,
			'cCondition': 0,
			'createdDate': '02/15/2018',
			'cStatus': 0,
			'cVINCode': '38624840090'
		 }
		//, {
		// 	'id': 12,
		// 	'cModel': 'Astro',
		// 	'cManufacture': 'Chevrolet',
		// 	'cModelYear': 1995,
		// 	'cMileage': 142137,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Chevrolet Astro was a rear-wheel drive van/minivan cManufactured and marketed by the American automaker Chevrolet from 1985 to 2005 and over two build generations. Along with its rebadged variant, the GMC Safari, the Astro was marketed in passenger as well as cargo and livery configurations—featuring a V6 engine, unibody construction with a separate front engine/suspension sub-frame, leaf-spring rear suspension, rear bi-parting doors, and a seating capacity of up to eight passengers`,
		// 	'cColor': 'Teal',
		// 	'cPrice': 72430,
		// 	'cCondition': 1,
		// 	'createdDate': '07/31/2017',
		// 	'cStatus': 0,
		// 	'cVINCode': 'KMHGH4JH2DU676107'
		// }, {
		// 	'id': 13,
		// 	'cModel': 'XL7',
		// 	'cManufacture': 'Suzuki',
		// 	'cModelYear': 2009,
		// 	'cMileage': 165165,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Suzuki XL-7 (styled as XL7 for the second generation) is Suzuki's mid-sized SUV that was made from 1998 to 2009, over two generations. It was slotted above the Grand Vitara in Suzuki's lineup.`,
		// 	'cColor': 'Puce',
		// 	'cPrice': 118667,
		// 	'cCondition': 0,
		// 	'createdDate': '02/04/2018',
		// 	'cStatus': 0,
		// 	'cVINCode': '1N6AF0LX9EN733005'
		// }, {
		// 	'id': 14,
		// 	'cModel': 'SJ 410',
		// 	'cManufacture': 'Suzuki',
		// 	'cModelYear': 1984,
		// 	'cMileage': 176074,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The SJ-Series was introduced to the United States (Puerto Rico (SJ-410) and Canada earlier) in 1985 for the 1986 cModel year. It was cPriced at $6200 and 47,000 were sold in its first year. The Samurai had a 1.3 liter, 63 hp (47 kW), 4-cylinder engine and was available as a convertible or a hardtop, and with or without a rear seat. The Suzuki Samurai became intensely popular within the serious 4WD community for its good off-road performance and reliability compared to other 4WDs of the time. This is due to the fact that while very compact and light, it is a real 4WD vehicle equipped with a transfer case, switchable 4WD and low range. Its lightness makes it a very nimble off-roader less prone to sinking in softer ground than heavier types. It is also considered a great beginner off-roader due to its simple design and ease of engine and suspension modifications.`,
		// 	'cColor': 'Orange',
		// 	'cPrice': 84325,
		// 	'cCondition': 0,
		// 	'createdDate': '12/22/2017',
		// 	'cStatus': 0,
		// 	'cVINCode': '2C3CDYBT6DH183756'
		// }, {
		// 	'id': 15,
		// 	'cModel': 'F-Series',
		// 	'cManufacture': 'Ford',
		// 	'cModelYear': 1995,
		// 	'cMileage': 53030,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Ford F-Series is a series of light-duty trucks and medium-duty trucks (Class 2-7) that have been marketed and cManufactured by Ford Motor Company since 1948. While most variants of the F-Series trucks are full-size pickup trucks, the F-Series also includes chassis cab trucks and commercial vehicles. The Ford F-Series has been the best-selling vehicle in the United States since 1986 and the best-selling pickup since 1977.[1][2] It is also the best selling vehicle in Canada.[3] As of the 2018 cModel year, the F-Series generates $41.0 billion in annual revenue for Ford, making the F-Series brand more valuable than Coca-Cola and Nike.`,
		// 	'cColor': 'Aquamarine',
		// 	'cPrice': 77108,
		// 	'cCondition': 0,
		// 	'createdDate': '01/09/2018',
		// 	'cStatus': 0,
		// 	'cVINCode': 'WBAVB33526P873481'
		// }, {
		// 	'id': 16,
		// 	'cModel': 'HS',
		// 	'cManufacture': 'Lexus',
		// 	'cModelYear': 2011,
		// 	'cMileage': 84718,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Lexus HS (Japanese: レクサス・HS, Rekusasu HS) is a dedicated hybrid vehicle introduced by Lexus as a new entry-level luxury compact sedan in 2009.[2] Built on the Toyota New MC platform,[3] it is classified as a compact under Japanese regulations concerning vehicle exterior dimensions and engine displacement. Unveiled at the North American International Auto Show in January 2009, the HS 250h went on sale in July 2009 in Japan, followed by the United States in August 2009 as a 2010 cModel. The HS 250h represented the first dedicated hybrid vehicle in the Lexus lineup, as well as the first offered with an inline-four gasoline engine.[4] Bioplastic materials are used for the vehicle interior.[5] With a total length of 184.8 inches, the Lexus HS is slightly larger than the Lexus IS, but still smaller than the mid-size Lexus ES.`,
		// 	'cColor': 'Purple',
		// 	'cPrice': 140170,
		// 	'cCondition': 0,
		// 	'createdDate': '11/14/2017',
		// 	'cStatus': 1,
		// 	'cVINCode': '1FTWF3A56AE545514'
		// }, {
		// 	'id': 17,
		// 	'cModel': 'Land Cruiser',
		// 	'cManufacture': 'Toyota',
		// 	'cModelYear': 2008,
		// 	'cMileage': 157019,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `Production of the first generation Land Cruiser began in 1951 (90 units) as Toyota's version of a Jeep-like vehicle.[2][3] The Land Cruiser has been produced in convertible, hardtop, station wagon and cab chassis versions. The Land Cruiser's reliability and longevity has led to huge popularity, especially in Australia where it is the best-selling body-on-frame, four-wheel drive vehicle.[4] Toyota also extensively tests the Land Cruiser in the Australian outback – considered to be one of the toughest operating environments in both temperature and terrain. In Japan, the Land Cruiser is exclusive to Toyota Japanese dealerships called Toyota Store.`,
		// 	'cColor': 'Crimson',
		// 	'cPrice': 72638,
		// 	'cCondition': 1,
		// 	'createdDate': '08/08/2017',
		// 	'cStatus': 1,
		// 	'cVINCode': '3C3CFFDR2FT957799'
		// }, {
		// 	'id': 18,
		// 	'cModel': 'Wrangler',
		// 	'cManufacture': 'Jeep',
		// 	'cModelYear': 1994,
		// 	'cMileage': 55857,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Jeep Wrangler is a series of compact and mid-size (Wrangler Unlimited and Wrangler 4-door JL) four-wheel drive off-road vehicle cModels, cManufactured by Jeep since 1986, and currently migrating from its third into its fourth generation. The Wrangler JL was unveiled in late 2017 and will be produced at Jeep's Toledo Complex.`,
		// 	'cColor': 'Red',
		// 	'cPrice': 193523,
		// 	'cCondition': 0,
		// 	'createdDate': '02/28/2018',
		// 	'cStatus': 1,
		// 	'cVINCode': '3C4PDCAB7FT652291'
		// }, {
		// 	'id': 19,
		// 	'cModel': 'Sunbird',
		// 	'cManufacture': 'Pontiac',
		// 	'cModelYear': 1994,
		// 	'cMileage': 165202,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Pontiac Sunbird is an automobile that was produced by Pontiac, initially as a subcompact for the 1976 to 1980 cModel years, and later as a compact for the 1982 to 1994 cModel years. The Sunbird badge ran for 18 years (with a hiatus during the 1981 and 1982 cModel years, as the 1982 cModel was called J2000) and was then replaced in 1995 by the Pontiac Sunfire. Through the years the Sunbird was available in notchback coupé, sedan, hatchback, station wagon, and convertible body styles.`,
		// 	'cColor': 'Blue',
		// 	'cPrice': 198739,
		// 	'cCondition': 0,
		// 	'createdDate': '05/13/2017',
		// 	'cStatus': 1,
		// 	'cVINCode': '1GD22XEG9FZ103872'
		// }, {
		// 	'id': 20,
		// 	'cModel': 'A4',
		// 	'cManufacture': 'Audi',
		// 	'cModelYear': 1998,
		// 	'cMileage': 117958,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The A4 has been built in five generations and is based on the Volkswagen Group B platform. The first generation A4 succeeded the Audi 80. The automaker's internal numbering treats the A4 as a continuation of the Audi 80 lineage, with the initial A4 designated as the B5-series, followed by the B6, B7, B8 and the B9. The B8 and B9 versions of the A4 are built on the Volkswagen Group MLB platform shared with many other Audi cModels and potentially one Porsche cModel within Volkswagen Group`,
		// 	'cColor': 'Yellow',
		// 	'cPrice': 159377,
		// 	'cCondition': 0,
		// 	'createdDate': '12/15/2017',
		// 	'cStatus': 1,
		// 	'cVINCode': '2C3CDXCT2FH350366'
		// }, {
		// 	'id': 21,
		// 	'cModel': 'Camry Solara',
		// 	'cManufacture': 'Toyota',
		// 	'cModelYear': 2006,
		// 	'cMileage': 22436,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Toyota Camry Solara, popularly known as the Toyota Solara, is a mid-size coupe/convertible built by Toyota. The Camry Solara is mechanically based on the Toyota Camry and effectively replaced the discontinued Camry Coupe (XV10); however, in contrast with its predecessor's conservative design, the Camry Solara was designed with a greater emphasis on sportiness, with more rakish styling, and uprated suspension and engine tuning intended to provide a sportier feel.[5] The coupe was launched in late 1998 as a 1999 cModel.[1] In 2000, the convertible was introduced, effectively replacing the Celica convertible in Toyota's North American lineup`,
		// 	'cColor': 'Green',
		// 	'cPrice': 122562,
		// 	'cCondition': 0,
		// 	'createdDate': '07/11/2017',
		// 	'cStatus': 0,
		// 	'cVINCode': '3C3CFFHH6DT874066'
		// }, {
		// 	'id': 22,
		// 	'cModel': 'Tribeca',
		// 	'cManufacture': 'Subaru',
		// 	'cModelYear': 2007,
		// 	'cMileage': 127958,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Subaru Tribeca is a mid-size crossover SUV made from 2005 to 2014. Released in some markets, including Canada, as the Subaru B9 Tribeca, the name "Tribeca" derives from the Tribeca neighborhood of New York City.[1] Built on the Subaru Legacy platform and sold in five- and seven-seat configurations, the Tribeca was intended to be sold alongside a slightly revised version known as the Saab 9-6. Saab, at the time a subsidiary of General Motors (GM), abandoned the 9-6 program just prior to its release subsequent to GM's 2005 divestiture of its 20 percent stake in FHI.`,
		// 	'cColor': 'Yellow',
		// 	'cPrice': 90221,
		// 	'cCondition': 1,
		// 	'createdDate': '11/12/2017',
		// 	'cStatus': 0,
		// 	'cVINCode': 'WVWGU7AN9AE957575'
		// }, {
		// 	'id': 23,
		// 	'cModel': '1500 Club Coupe',
		// 	'cManufacture': 'GMC',
		// 	'cModelYear': 1997,
		// 	'cMileage': 95783,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `GMC (General Motors Truck Company), formally the GMC Division of General Motors LLC, is a division of the American automobile cManufacturer General Motors (GM) that primarily focuses on trucks and utility vehicles. GMC sells pickup and commercial trucks, buses, vans, military vehicles, and sport utility vehicles marketed worldwide by General Motors.`,
		// 	'cColor': 'Teal',
		// 	'cPrice': 64376,
		// 	'cCondition': 1,
		// 	'createdDate': '06/28/2017',
		// 	'cStatus': 0,
		// 	'cVINCode': 'SCFBF04BX7G920997'
		// }, {
		// 	'id': 24,
		// 	'cModel': 'Firebird',
		// 	'cManufacture': 'Pontiac',
		// 	'cModelYear': 2002,
		// 	'cMileage': 74063,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Pontiac Firebird is an American automobile built by Pontiac from the 1967 to the 2002 cModel years. Designed as a pony car to compete with the Ford Mustang, it was introduced 23 February 1967, the same cModel year as GM's Chevrolet division platform-sharing Camaro.[1] This also coincided with the release of the 1967 Mercury Cougar, Ford's upscale, platform-sharing version of the Mustang. The name "Firebird" was also previously used by GM for the General Motors Firebird 1950s and early-1960s`,
		// 	'cColor': 'Puce',
		// 	'cPrice': 94178,
		// 	'cCondition': 1,
		// 	'createdDate': '09/13/2017',
		// 	'cStatus': 0,
		// 	'cVINCode': '3C63D2JL5CG563879'
		// }, {
		// 	'id': 25,
		// 	'cModel': 'RAV4',
		// 	'cManufacture': 'Toyota',
		// 	'cModelYear': 1996,
		// 	'cMileage': 99461,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Toyota RAV4 (Japanese: トヨタ RAV4 Toyota Ravufō) is a compact crossover SUV (sport utility vehicle) produced by the Japanese automobile cManufacturer Toyota. This was the first compact crossover SUV;[1] it made its debut in Japan and Europe in 1994,[2] and in North America in 1995. The vehicle was designed for consumers wanting a vehicle that had most of the benefits of SUVs, such as increased cargo room, higher visibility, and the option of full-time four-wheel drive, along with the maneuverability and fuel economy of a compact car. Although not all RAV4s are four-wheel-drive, RAV4 stands for "Recreational Activity Vehicle: 4-wheel drive", because the aforementioned equipment is an option in select countries`,
		// 	'cColor': 'Goldenrod',
		// 	'cPrice': 48342,
		// 	'cCondition': 0,
		// 	'createdDate': '12/29/2017',
		// 	'cStatus': 0,
		// 	'cVINCode': '2C4RDGDG6DR836144'
		// }, {
		// 	'id': 26,
		// 	'cModel': 'Amanti / Opirus',
		// 	'cManufacture': 'Kia',
		// 	'cModelYear': 2007,
		// 	'cMileage': 189651,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Kia Opirus was an executive car cManufactured and marketed by Kia Motors that was launched in April 2003 and was marketed globally under various nameplates, prominently as the Amanti. It was considered to be Kia's flagship vehicle.`,
		// 	'cColor': 'Indigo',
		// 	'cPrice': 44292,
		// 	'cCondition': 1,
		// 	'createdDate': '09/01/2017',
		// 	'cStatus': 1,
		// 	'cVINCode': '1C4SDHCT2CC055294'
		// }, {
		// 	'id': 27,
		// 	'cModel': 'S60',
		// 	'cManufacture': 'Volvo',
		// 	'cModelYear': 2001,
		// 	'cMileage': 78963,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `First introduced in 2004, Volvo's S60 R used a Haldex all-wheel-drive system mated to a 300 PS (221 kW; 296 hp) / 400 N⋅m (300 lbf⋅ft) inline-5. The 2004–2005 cModels came with a 6-speed manual transmission, or an available 5-speed automatic which allowed only 258 lb⋅ft (350 N⋅m) torque in 1st and 2nd gears. The 2006–2007 cModels came with a 6-speed manual or 6-speed automatic transmission (which was no longer torque-restricted)`,
		// 	'cColor': 'Goldenrod',
		// 	'cPrice': 9440,
		// 	'cCondition': 0,
		// 	'createdDate': '11/06/2017',
		// 	'cStatus': 0,
		// 	'cVINCode': '3C6TD5CT5CG316067',
		// }, {
		// 	'id': 28,
		// 	'cModel': 'Grand Marquis',
		// 	'cManufacture': 'Mercury',
		// 	'cModelYear': 1984,
		// 	'cMileage': 153027,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Mercury Grand Marquis is an automobile that was sold by the Mercury division of Ford Motor Company from 1975 to 2011. From 1975 to 1982, it was the premium cModel of the Mercury Marquis cModel line, becoming a standalone cModel line in 1983. For its entire production run, the Grand Marquis served as the flagship of the Mercury line, with the Ford (LTD) Crown Victoria serving as its Ford counterpart. In addition, from 1979 to 2011, the Grand Marquis shared the rear-wheel drive Panther platform alongside the Lincoln Town Car`,
		// 	'cColor': 'Goldenrod',
		// 	'cPrice': 76027,
		// 	'cCondition': 0,
		// 	'createdDate': '12/16/2017',
		// 	'cStatus': 1,
		// 	'cVINCode': '3C3CFFJH2DT871398'
		// }, {
		// 	'id': 29,
		// 	'cModel': 'Talon',
		// 	'cManufacture': 'Eagle',
		// 	'cModelYear': 1991,
		// 	'cMileage': 111234,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `Cosmetically, differences between the three were found in wheels, availability of cColors, tail lights, front and rear bumpers, and spoilers. The Talon featured two-tone body cColor with a black 'greenhouse' (roof, pillars, door-mounted mirrors) regardless of the body cColor. The variants featured 5-speed manual or 4-speed automatic transmissions and a hood bulge on the left-hand side of the car in order for camshaft clearance on the 4G63 engine. The base cModel DL did not use this engine but still had a bulge as evident in the 1992 Talon brochure. 2nd Generation cars all had such a bulge, even with the inclusion of the 420A engine`,
		// 	'cColor': 'Teal',
		// 	'cPrice': 157216,
		// 	'cCondition': 0,
		// 	'createdDate': '05/08/2017',
		// 	'cStatus': 1,
		// 	'cVINCode': 'YV1902FH1D2957659'
		// }, {
		// 	'id': 30,
		// 	'cModel': 'Passport',
		// 	'cManufacture': 'Honda',
		// 	'cModelYear': 2002,
		// 	'cMileage': 3812,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Passport was a part of a partnership between Isuzu and Honda in the 1990s, which saw an exchange of passenger vehicles from Honda to Isuzu, such as the Isuzu Oasis, and trucks from Isuzu to Honda, such as the Passport and Acura SLX. This arrangement was convenient for both companies, as Isuzu discontinued passenger car production in 1993 after a corporate restructuring, and Honda was in desperate need a SUV, a segment that was growing in popularity in North America as well as Japan during the 1990s. The partnership ended in 2002 with the discontinuation of the Passport in favor of the Honda-engineered Pilot`,
		// 	'cColor': 'Puce',
		// 	'cPrice': 41299,
		// 	'cCondition': 1,
		// 	'createdDate': '03/08/2018',
		// 	'cStatus': 0,
		// 	'cVINCode': 'WVWEU9AN4AE524071'
		// }, {
		// 	'id': 31,
		// 	'cModel': 'H3',
		// 	'cManufacture': 'Hummer',
		// 	'cModelYear': 2006,
		// 	'cMileage': 196321,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Hummer H3 is a sport utility vehicle/off-road vehicle from Hummer that was produced from 2005 to 2010. Introduced for the 2006 cModel year, it was based on a highly modified GMT355 underpinning the Chevrolet cColorado/GMC Canyon compact pickup trucks that were also built at GM's Shreveport Operations in Shreveport, Louisiana and the Port Elizabeth plant in South Africa. The H3 was actually the smallest among the Hummer cModels. It was available either as a traditional midsize SUV or as a midsize pickup known as the H3T`,
		// 	'cColor': 'Pink',
		// 	'cPrice': 186964,
		// 	'cCondition': 1,
		// 	'createdDate': '06/04/2017',
		// 	'cStatus': 1,
		// 	'cVINCode': '4T1BF1FK4FU746230',
		// }, {
		// 	'id': 32,
		// 	'cModel': 'Comanche',
		// 	'cManufacture': 'Jeep',
		// 	'cModelYear': 1992,
		// 	'cMileage': 72285,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The Jeep Comanche (designated MJ) is a pickup truck variant of the Cherokee compact SUV (1984–2001)[3] cManufactured and marketed by Jeep for cModel years 1986-1992 in rear wheel (RWD) and four-wheel drive (4WD) cModels as well as two cargo bed lengths: six-feet (1.83 metres) and seven-feet (2.13 metres)`,
		// 	'cColor': 'Mauv',
		// 	'cPrice': 145971,
		// 	'cCondition': 1,
		// 	'createdDate': '09/01/2017',
		// 	'cStatus': 0,
		// 	'cVINCode': '1J4PN2GK1BW745045'
		// }, {
		// 	'id': 33,
		// 	'cModel': 'Blazer',
		// 	'cManufacture': 'Chevrolet',
		// 	'cModelYear': 1993,
		// 	'cMileage': 189804,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The 2014 – 2nd generation, MY14 Duramax 2.8L diesel engines have several new parts, namely a new water-cooled variable-geometry turbocharger, a new high-pressure common-rail fuel delivery system, a new exhaust gas recirculation (EGR) system, a new intake manifold, a new cylinder head, a new cylinder block, a new balance shaft unit and a new Engine Control Module (ECM). and now produce 197 hp and 369 Ft/Lbs of torque`,
		// 	'cColor': 'Indigo',
		// 	'cPrice': 154594,
		// 	'cCondition': 0,
		// 	'createdDate': '09/13/2017',
		// 	'cStatus': 0,
		// 	'cVINCode': '1G6KD57Y43U482896'
		// }, {
		// 	'id': 34,
		// 	'cModel': 'Envoy XUV',
		// 	'cManufacture': 'GMC',
		// 	'cModelYear': 2004,
		// 	'cMileage': 187960,
		// 	// tslint:disable-next-line:max-line-length
		// 	'cDescription': `The GMC Envoy is a mid-size SUV that was produced by General Motors. It was introduced for the 1998 cModel year. After the first generation Envoy was discontinued after the 2000 cModel year, but the Envoy was reintroduced and redesigned for the 2002 cModel year, and it was available in the GMC line of vehicles from the 2002 to 2009 cModel years`,
		// 	'cColor': 'Turquoise',
		// 	'cPrice': 185103,
		// 	'cCondition': 1,
		// 	'createdDate': '12/07/2017',
		// 	'cStatus': 0,
		// 	'cVINCode': '5GAER23D09J658030'
		// }
	];
}
