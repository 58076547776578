export class Pais {
    id: number;    
    nome: string;
    sigla: string;

	clear() {
        this.id = 0;
        this.nome = '';
        this.sigla = '';
	}
}