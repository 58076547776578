export class Unidade {
    id: number;    
    nome: string;
    rua: string;
    numero: number;
    complemento: string;
    bairro: string;
    idCidade: number;
    telefone: string;
    email: string;
}